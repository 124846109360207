<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import type { TypeNavigationItemSkeleton } from '~/types/contentful'

  defineProps<{
    headerLogoUrl: string | undefined
    isSignedIn: boolean | null
    mobileMenuNavLinks: (Entry<TypeNavigationItemSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined)[]
  }>()

  const $emit = defineEmits<{
    (event: 'menu-open'): void
    (event: 'menu-close'): void
  }>()

  const isMobileMenuOpen = ref<boolean>(false)

  watchEffect(() => {
    // Close mobile menu when screen size is md and up.
    // This is for when the user resizes the screen while the mobile menu is open.
    if (mdAndUp.value && isMobileMenuOpen.value) {
      isMobileMenuOpen.value = false
    }
  })

  function onMobileMenuOpen() {
    $emit('menu-open')
    isMobileMenuOpen.value = true
  }

  function onMobileMenuClose() {
    $emit('menu-close')
    // Close mobile menu after ordering app panel slideout animation
    setTimeout(() => {
      isMobileMenuOpen.value = false
    }, 500)
  }

  const AsyncMobileMenu = defineAsyncComponent({
    loader: () => import('@/components/navigation/CNavMenuMobile/CNavMenuMobile.vue'),
    suspensible: false
  })
</script>

<template>
  <div class="c-nav-container">
    <nav class="c-navbar" role="menubar">
      <div class="col-start">
        <nuxt-link href="/" role="menuitem">
          <nuxt-img :src="headerLogoUrl" alt="Grill'd" class="logo-grilld" provider="contentful" />
        </nuxt-link>

        <client-only>
          <template v-if="!mdAndUp">
            <!--   Mobile   -->
            <icon-ionic-menu
              v-if="!isMobileMenuOpen"
              :class="['menu-icon', { 'menu--closed': !isMobileMenuOpen }]"
              aria-label="menu"
              role="button"
              tabindex="0"
              @click="onMobileMenuOpen"
              @keydown.enter="onMobileMenuOpen"
            />

            <icon-ionic-close
              v-else
              :class="['menu-icon', { 'menu--open': isMobileMenuOpen }]"
              aria-label="close"
              filled
              role="button"
              tabindex="0"
              @click="isMobileMenuOpen = false"
              @keydown.enter="isMobileMenuOpen = false"
            />
          </template>
        </client-only>
      </div>

      <div class="col-end">
        <slot />
      </div>
    </nav>

    <AsyncMobileMenu
      :is-open="isMobileMenuOpen"
      :is-signed-in="isSignedIn"
      :nav-links="mobileMenuNavLinks"
      @close="onMobileMenuClose"
    />
  </div>
</template>

<style lang="scss" scoped>
  .c-nav-container {
    position: relative;
    z-index: 100;
    display: block;
    background-color: var(--color-secondary-paper-light);
    border-bottom: 1px solid var(--color-base-paper);

    padding: 12px 16px;

    @include screenMdAndUp {
      padding: 16px 24px;
    }
  }

  .c-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto;
    max-width: var(--breakpoint-xl);

    .col-start {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      column-gap: 12px;

      @include screenSmAndUp {
        column-gap: 24px;
      }

      .logo-grilld {
        height: 48px;
        width: 48px;
        cursor: pointer;

        @include screenMdAndUp {
          height: 86px;
          width: 86px;
        }
      }

      .menu-icon {
        cursor: pointer;
        height: 32px;
      }
    }

    .col-end {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 12px;

      @include screenMdAndUp {
        column-gap: 16px;
      }

      @include screenLgAndUp {
        column-gap: 24px;
      }
    }
  }

  @keyframes fade-icon-menu {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-icon-menu {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .menu--open {
    animation: fade-icon-menu 150ms ease-out forwards;
  }

  .menu--closed {
    animation: fade-icon-menu 150ms ease-out backwards;
  }
</style>
